













































import FormInputMixin from '@/mixins/FormInputMixin';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof FormInputMixin>
>).extend({
  mixins: [FormInputMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    },
    showHint: {
      type: Boolean,
      default: false
    },
    showForgotPasswordLink: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    rules: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      type: 'password',
      eye: 'eye',
      model: null
    };
  },
  watch: {
    model: function(val) {
      this.$emit('onValueChange', val);
    }
  },
  methods: {
    showHidePassword() {
      if (this.type === 'password') {
        this.type = 'text';
        this.eye = 'eye-slash';
      } else {
        this.type = 'password';
        this.eye = 'eye';
      }
    }
  }
});
