


















import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import UserForm from '@/components/auth/UserForm.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { User } from '@/model/user';

export default Vue.extend({
  components: {
    UserForm,
    LoadingIndicator
  },
  computed: {
    ...mapStores(useUsersStore),
    loggedInUserId(): number {
      return this.usersStore.loggedInUserId as number;
    },
    savingUser(): boolean {
      return this.usersStore.user.saving;
    },
    user(): User {
      return this.usersStore.user.entity;
    },
    loading(): boolean {
      return this.usersStore.user.fetching;
    },
    validationErrors(): any {
      return this.usersStore.user.validationErrors;
    }
  },
  async created() {
    await this.getUser();
  },
  methods: {
    getUser() {
      return this.usersStore.getUser(this.loggedInUserId);
    },
    async editUser(user) {
      const customerSuccessfullyEdited = await this.usersStore.edit(user);
      await this.getUser();
      if (customerSuccessfullyEdited) {
        this.$router.push({ name: 'MyAccount', params: { vendor_id: '1' } });
      }
    }
  }
});
