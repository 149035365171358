




























































































































import TextInput from '@/components/shared/forms/TextInput.vue';
import RadioInput from '@/components/shared/forms/RadioInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import PasswordInput from '@/components/shared/forms/PasswordInput.vue';
import PhoneInput from '@/components/shared/forms/PhoneInput.vue';
import { Gender } from '@/model/gender';
import Vue, { PropType } from 'vue';
import { User } from '@/model/user';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { ValidationObserver } from 'vee-validate';

export default Vue.extend({
  components: {
    TextInput,
    RadioInput,
    PasswordInput,
    SubmitButton,
    PhoneInput,
    ValidationObserver
  },
  props: {
    userToEdit: {
      type: Object as PropType<User>,
      default: () => ({} as User)
    },
    savingUser: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    validateOnLoad: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      GENDER: Gender,
      password: '',
      user: {
        email:
          this.userToEdit && this.userToEdit.email ? this.userToEdit.email : '',
        username:
          this.userToEdit && this.userToEdit.username
            ? this.userToEdit.username
            : '',
        gender:
          this.userToEdit && this.userToEdit.gender
            ? this.userToEdit.gender
            : Gender.OTHER,
        lastname:
          this.userToEdit && this.userToEdit.lastname
            ? this.userToEdit.lastname
            : '',
        firstname:
          this.userToEdit && this.userToEdit.firstname
            ? this.userToEdit.firstname
            : '',
        // FIXME: temporaire avant site public
        mobile_phone:
          // @ts-ignore
          this.userToEdit && this.userToEdit.mobile_phone
            ? // @ts-ignore
              this.userToEdit.mobile_phone
            : ''
      } as Partial<User>
    };
  },
  computed: {
    ...mapStores(useUsersStore),
    isLoggedUser(): boolean {
      return (
        Object.keys(this.userToEdit).length > 0 &&
        this.userToEdit.id === this.usersStore.loggedInUserId
      );
    },
    dataModelForApi(): Partial<User> {
      const user = {
        ...this.user
      };
      if (this.userToEdit) {
        user.id = this.userToEdit.id;
      }
      if (this.password) {
        user.password = this.password;
      }
      return user;
    }
  },
  mounted() {
    if (this.validateOnLoad === true) {
      this.validate();
    }
  },
  beforeDestroy() {
    (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();
  },
  methods: {
    async validate() {
      return await (this.$refs.observer as InstanceType<
        typeof ValidationObserver
      >).validate();
    },
    async onSubmit() {
      this.$emit('onFormValidated', this.dataModelForApi);
    }
  }
});
