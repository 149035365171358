var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('text-input',{attrs:{"value":_vm.user.email,"errors":_vm.errors,"rules":"required|email","name":"email","label":_vm.$t('label.email'),"type":"email"},on:{"onValueChange":function (value) {
          _vm.user.email = value;
        }}}),_c('text-input',{attrs:{"value":_vm.user.username,"errors":_vm.errors,"name":"username","optional":true,"label":_vm.$t('label.username'),"help-text":_vm.$t('signUp.usernameHint')},on:{"onValueChange":function (value) {
          _vm.user.username = value;
        }}}),_c('password-input',{attrs:{"show-hint":true,"errors":_vm.errors,"name":"password","rules":"minLength:6","placeholder":_vm.isLoggedUser ? _vm.$t('customerForm.passwordPlaceholder') : ''},on:{"onValueChange":function (value) {
          _vm.password = value;
        }}}),_c('radio-input',{attrs:{"default-value":_vm.user.gender,"options":[
        { value: _vm.GENDER.FEMALE, label: _vm.$t('label.mrs') },
        { value: _vm.GENDER.MALE, label: _vm.$t('label.mr') },
        { value: _vm.GENDER.OTHER, label: _vm.$t('label.genderOther') }
      ],"errors":_vm.errors,"rules":"required","inline":true,"name":"gender","label":_vm.$t('label.gender')},on:{"onValueChange":function (value) {
          _vm.user.gender = value;
        }}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('text-input',{attrs:{"value":_vm.user.firstname,"errors":_vm.errors,"rules":"required","name":"firstname","label":_vm.$t('label.firstname')},on:{"onValueChange":function (value) {
              _vm.user.firstname = value;
            }}})],1),_c('div',{staticClass:"col-md-6"},[_c('text-input',{attrs:{"value":_vm.user.lastname,"errors":_vm.errors,"rules":"required","name":"lastname","label":_vm.$t('label.lastname')},on:{"onValueChange":function (value) {
              _vm.user.lastname = value;
            }}})],1)]),_c('phone-input',{attrs:{"value":_vm.user.mobile_phone,"errors":_vm.errors,"name":"mobile_phone","rules":"required|phone","label":_vm.$t('label.mobile_phone')},on:{"onValueChange":function (value) {
          _vm.user.mobile_phone = value;
        }}}),_c('div',{staticClass:"tw-flex tw-justify-end tw-gap-4"},[(_vm.showCancel)?_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]):_vm._e(),_c('submit-button',{attrs:{"loading":_vm.savingUser,"disabled":invalid}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }